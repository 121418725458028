import React from "react";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { Link } from "react-router-dom";
import numeral from "numeral";
import { dateToTimezone } from "./../../../utils";

const Table = React.memo((props) => {
    const uploadInvoice = (purchaseOrder) => {
        props.setField("po_number", purchaseOrder.qb_number);
        props.setField("vendor_id", purchaseOrder.vendor_id);
        props.setPoFieldDisabled(true);
        props.setSendEmail(0);
        props.setEmails("");
        props.setSendShortPayEmail(0);
        props.setShortPayEmails("");
        props.showFormModal();
    };

    const renderPurchaseOrders = () => {
        if (props.fetchingPurchaseOrders) {
            return (
                <tr>
                    <td colSpan="18">
                        <i className="fas fa-circle-notch fa-spin" /> Loading
                        data...
                    </td>
                </tr>
            );
        }

        if (props.purchaseOrdersData.length === 0) {
            return (
                <tr>
                    <td colSpan="18">No Results</td>
                </tr>
            );
        }

        return props.purchaseOrdersData.map((purchaseOrder, index) => {
            let total = 0;
            if (purchaseOrder.total != null) {
                total = purchaseOrder.total.toFixed(2);
            }

            let scLink = "-";
            if (
                purchaseOrder.stagecoach_number != null &&
                purchaseOrder.stagecoach_number !== ""
            ) {
                scLink = (
                    <Link to={"/tracking/" + purchaseOrder.stagecoach_number}>
                        {purchaseOrder.stagecoach_number}
                    </Link>
                );
            }

            let createdAt = "-";
            if (purchaseOrder.created_at != null) {
                createdAt = dateToTimezone(
                    purchaseOrder.created_at,
                    "MM/DD/YYYY"
                );
            }

            let pushedDate = "-";
            if (purchaseOrder.pushed_datetime != null) {
                pushedDate = dateToTimezone(
                    purchaseOrder.pushed_datetime,
                    "MM/DD/YYYY"
                );
            }

            return (
                <tr key={"purchase-order-" + index}>
                    <td>
                        {purchaseOrder.qb_number
                            ? purchaseOrder.qb_number
                            : "-"}
                    </td>
                    <td>{purchaseOrder.vendor_obj_name}</td>
                    <td>{scLink}</td>
                    <td>{purchaseOrder.created_by_name}</td>
                    <td>{createdAt}</td>
                    <td>{pushedDate}</td>
                    <td>{purchaseOrder.descriptions}</td>
                    <td className="text-right">
                        {numeral(total).format("$0,0.00")}
                    </td>
                    <td>
                        <div className="actions">
                            <Tooltip
                                title="View PO"
                                position="bottom"
                                arrow
                                size="small"
                                className="action-tooltip"
                            >
                                <Link
                                    to={"/purchase-order/" + purchaseOrder.id}
                                    target="_blank"
                                >
                                    <i className="fas fa-info-circle" />
                                </Link>
                            </Tooltip>
                            <Tooltip
                                title="Create Invoice"
                                position="bottom"
                                arrow
                                size="small"
                                className="action-tooltip"
                            >
                                <i
                                    className="fas fa-file-pdf"
                                    onClick={uploadInvoice.bind(
                                        this,
                                        purchaseOrder
                                    )}
                                />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const renderTable = () => {
        if (props.purchaseOrdersData.length === 0) {
            return null;
        }

        return (
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            <th>PO #</th>
                            <th>Vendor</th>
                            <th>SC #</th>
                            <th>Created By</th>
                            <th>Created Date</th>
                            <th>Pushed Date</th>
                            <th>Description</th>
                            <th className="th-total text-right">Total</th>
                            <th style={{ width: 80, textAlign: "center" }}></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{renderPurchaseOrders()}</tbody>
                </table>
            </div>
        );
    };

    const handleSetPoNumber = (event) => {
        props.setPoNumber(event.target.value);
    };

    const handleFetchPurchaseOrders = () => {
        props.fetchPurchaseOrders();
    };

    const handleKey = (event) => {
        if (event.key === "Enter") {
            props.fetchPurchaseOrders();
        }
    };

    let buttonLabel = "Search";
    if (props.fetchingPurchaseOrders) {
        buttonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    return (
        <div className="table-root-container">
            <h3>PO Search</h3>
            <div className="search-field">
                PO #:
                <input
                    type="text"
                    value={props.poNumber}
                    onKeyPress={handleKey}
                    onChange={handleSetPoNumber}
                />
                <button
                    className="action-button"
                    disabled={props.fetchingPurchaseOrders}
                    onClick={handleFetchPurchaseOrders}
                >
                    {buttonLabel}
                </button>
            </div>
            {renderTable()}
        </div>
    );
});

export default Table;
