import axios from "axios";
import * as types from "./ApDashboard.types";
import * as selectors from "./ApDashboard.selectors";
import {
    validateAuthInResponse,
    showNotification,
    empty,
    showDataDeletedNotification,
} from "./../../utils";
import * as dialog from "../common/dialog";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchPurchaseOrders = () => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());
    const number = selectors.getPoNumber(getState());

    if (number === "") {
        return showNotification("Enter PO #", "Please, enter a PO #", "info");
    }

    let params = [];
    params.push("sort=" + (sort.type === "asc" ? "" : "-") + sort.column);
    params.push("active-page=" + pagination.activePage);
    params.push("elements-per-page=" + pagination.elementsPerPage);
    params.push("only-pushed=" + 1);
    params.push("number=" + number);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(
                filerFields[i]["name"] + "=" + filerFields[i]["value"]
            );
        }
        params.push("filter=" + filterElements.join(","));
    } else {
        params.push("filter=-");
    }

    dispatch({ type: types.FETCH_PURCHASE_ORDERS, payload: null });
    axios
        .get(apiUrl + "/purchase-orders?" + params.join("&"), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            if (response.data.elements.length === 0) {
                showNotification(
                    "PO not fount",
                    "The PO # does not exist",
                    "info"
                );
            }

            dispatch({
                type: types.FETCH_PURCHASE_ORDERS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch(
                validateAuthInResponse(error, types.FETCH_PURCHASE_ORDERS_ERROR)
            );
        });
};

export const fetchInformation = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_INFORMATION, payload: null });
    axios
        .get(apiUrl + "/ap-invoice/information", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            dispatch({
                type: types.FETCH_INFORMATION_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch(
                validateAuthInResponse(error, types.FETCH_INFORMATION_ERROR)
            );
        });
};

export const fetchInvoices = (activeTab) => (dispatch, getState) => {
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const filerFields = selectors.getFilterFields(getState());

    let params = [];
    params.push("sort=" + (sort.type === "asc" ? "" : "-") + sort.column);
    params.push("active-page=" + pagination.activePage);
    params.push("elements-per-page=" + pagination.elementsPerPage);
    params.push("active-tab=" + activeTab);

    if (filerFields.length > 0) {
        let filterElements = [];
        for (let i = 0; i < filerFields.length; i++) {
            filterElements.push(
                filerFields[i]["name"] + "=" + filerFields[i]["value"]
            );
        }
        params.push("filter=" + filterElements.join(","));
    } else {
        params.push("filter=-");
    }

    dispatch({ type: types.FETCH_INVOICES, payload: null });
    axios
        .get(apiUrl + "/ap-invoices?" + params.join("&"), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            dispatch({
                type: types.FETCH_INVOICES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_INVOICES_ERROR));
        });
};

export const saveInvoice = () => (dispatch, getState) => {
    const fields = selectors.getFields(getState());
    const file = selectors.getFile(getState());
    const activeTab = selectors.getActiveTab(getState());
    const sendEmail = selectors.getSendEmail(getState());
    const emails = selectors.getEmails(getState());
    const sendShortPayEmail = selectors.getSendShortPayEmail(getState());
    const shortPayEmails = selectors.getShortPayEmails(getState());

    let isUpdate = false;
    if (!empty(fields.id)) {
        isUpdate = true;
    }

    if (fields.invoice_number == null || fields.invoice_number === "") {
        return showNotification(
            "Complete Information",
            "Enter the Invoice Number",
            "info"
        );
    }
    if (fields.invoice_date == null || fields.invoice_date === "") {
        return showNotification(
            "Complete Information",
            "Enter the Invoice Date",
            "info"
        );
    }
    if (fields.amount == null || fields.amount === "") {
        return showNotification(
            "Complete Information",
            "Enter the amount",
            "info"
        );
    }
    if (fields.po_match == null || fields.po_match === "") {
        return showNotification(
            "Complete Information",
            "Select if the Invoice/PO match",
            "info"
        );
    }
    if (!isUpdate && file == null) {
        return showNotification(
            "Complete Information",
            "Select a file to upload",
            "info"
        );
    }

    let data = new FormData();
    data.append("file", file);
    data.append("invoice_number", fields.invoice_number);
    data.append("invoice_date", fields.invoice_date);
    data.append("vendor_id", fields.vendor_id);
    data.append("po_match", fields.po_match);
    data.append("po_number", fields.po_number);
    data.append("amount", fields.amount);
    data.append("notes", fields.notes);
    data.append("memo", fields.memo);
    data.append("sendEmail", sendEmail);
    data.append("emails", emails);
    data.append("sendShortPayEmail", sendShortPayEmail);
    data.append("shortPayEmails", shortPayEmails);

    let endpoint = apiUrl + "/ap-invoice";
    if (!empty(fields.id)) {
        endpoint = apiUrl + "/ap-invoice/update-post";
        data.append("id", fields.id);
    }

    dispatch({ type: types.SAVE_INVOICE, payload: null });
    axios
        .post(endpoint, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            dispatch({
                type: types.SAVE_INVOICE_SUCCESS,
                payload: response.data,
            });
            showNotification(
                "Success",
                "Invoice saved successfully",
                "success"
            );
            dispatch(dialog.actions.hideDialog("ap-invoice-form-modal"));
            dispatch(fetchInvoices(activeTab));
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message);
                showNotification(
                    "Wrong File Format",
                    error.response.data.error.message,
                    "warning"
                );
            } else {
                dispatch(
                    validateAuthInResponse(error, types.SAVE_INVOICE_ERROR)
                );
            }
        });
};

export const deleteInvoice = (id) => (dispatch, getState) => {
    const activeTab = selectors.getActiveTab(getState());

    dispatch({ type: types.DELETE_INVOICE, payload: null });
    axios
        .delete(apiUrl + "/ap-invoices/" + id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then(() => {
            dispatch({ type: types.DELETE_INVOICE_SUCCESS, payload: null });
            showDataDeletedNotification();
            dispatch(fetchInvoices(activeTab));
            dispatch(dialog.actions.hideDialog("delete-modal"));
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.DELETE_INVOICE_ERROR));
        });
};

export const checkPo = (pos) => (dispatch, getState) => {
    dispatch({ type: types.CHECK_PO, payload: null });
    axios
        .get(apiUrl + "/ap-invoice/check-po?pos=" + pos, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            dispatch({
                type: types.CHECK_PO_SUCCESS,
                payload: response.data.message,
            });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CHECK_PO_ERROR));
        });
};

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
};

export const setSort = (column, type) => (dispatch, getState) => {
    const activeTab = selectors.getActiveTab(getState());

    new Promise((resolve) => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            },
        });
        resolve();
    }).then(() => {
        dispatch(fetchInvoices(activeTab));
    });
};

export const setActivePage = (value) => (dispatch, getState) => {
    const activeTab = selectors.getActiveTab(getState());

    new Promise((resolve) => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: value,
        });
        resolve();
    }).then(() => {
        dispatch(fetchInvoices(activeTab));
    });
};

export const mountComponent = () => (dispatch) => {
    dispatch(resetState());
    dispatch(fetchInformation());
    // dispatch(fetchPurchaseOrders());
};

export const setIdForDelete = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_FOR_DELETE,
        payload: id,
    });
};
export const setFile = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: value,
    });
};
export const setPoNumber = (value) => (dispatch) => {
    dispatch({
        type: types.SET_PO_NUMBER,
        payload: value,
    });
};
export const setActiveTab = (value) => (dispatch) => {
    if ([2, 3, 4, 5].includes(value)) {
        dispatch(fetchInvoices(value));
    }

    dispatch({
        type: types.SET_ACTIVE_TAB,
        payload: value,
    });
};
export const setField = (field, value) => (dispatch) => {
    dispatch({
        type: types.SET_FIELD,
        payload: { field, value },
    });
};
export const clearForm = () => (dispatch) => {
    dispatch({
        type: types.CLEAR_FORM,
        payload: null,
    });
};
export const setPoFieldDisabled = (value) => (dispatch) => {
    dispatch({
        type: types.SET_PO_FIELD_DISABLED,
        payload: value,
    });
};
export const setSendEmail = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEND_EMAIL,
        payload: value,
    });
};
export const setEmails = (value) => (dispatch) => {
    dispatch({
        type: types.SET_EMAILS,
        payload: value,
    });
};
export const setSendShortPayEmail = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SEND_SHORT_PAY_EMAIL,
        payload: value,
    });
};
export const setShortPayEmails = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SHORT_PAY_EMAILS,
        payload: value,
    });
};
export const setAuditHistory = (value) => (dispatch) => {
    dispatch({
        type: types.SET_AUDIT_HISTORY,
        payload: value,
    });
};

export const changeFilter = (name, value) => (dispatch, getState) => {
    const activeTab = selectors.getActiveTab(getState());

    new Promise((resolve) => {
        dispatch({
            type: types.CHANGE_FILTER,
            payload: { name, value },
        });
        resolve();
    }).then(() => {
        dispatch(fetchInvoices(activeTab));
    });
};
