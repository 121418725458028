import React from "react";
import { Dialog } from "./../../common/dialog";
import { useDropzone } from "react-dropzone";
import { empty } from "../../../utils";
import { dateToTimezone } from "./../../../utils";

const Form = React.memo((props) => {
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: ".pdf",
        onDrop: (files) => {
            props.setFile(files[0]);
        },
    });

    let isUpdate = false;
    if (!empty(props.fields.id)) {
        isUpdate = true;
    }

    const hideHandler = () => {
        props.hideFormModal();
        props.clearForm();
    };

    const handleSendEmailClick = (event) => {
        let value = event.target.checked ? 1 : 0;
        props.setSendEmail(value);
    };

    const handleSendShortPayEmailClick = (event) => {
        let value = event.target.checked ? 1 : 0;
        props.setSendShortPayEmail(value);
    };

    const renderAuditHistory = () => {
        if (props.auditHistory.length === 0) {
            return null;
        }

        return (
            <div className="audit-history-container">
                <div className="title">Audit Log:</div>
                <div className="log">
                    {props.auditHistory.map((record, index) => (
                        <div
                            className="log-element"
                            key={"log-element-" + index}
                        >
                            <b className={record.is_update ? "pink-value" : ""}>
                                {dateToTimezone(
                                    record.created_at,
                                    "MMMM Do YYYY, h:mm a"
                                )}
                                :
                            </b>{" "}
                            <span
                                className="label"
                                dangerouslySetInnerHTML={{
                                    __html: record.label,
                                }}
                            ></span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSendEmailsFields = () => {
        if (
            props.fields.po_match === "" ||
            parseInt(props.fields.po_match) === 1
        ) {
            return null;
        }

        return (
            <>
                <div className="divider"></div>
                <div className="field field-half checkbox-field">
                    <label>
                        <input
                            type="checkbox"
                            value={props.sendEmail}
                            checked={props.sendEmail}
                            onClick={handleSendEmailClick}
                        />{" "}
                        Send email notification
                    </label>
                </div>
                <div className="field field-half">
                    <div className="label">
                        Additional Emails (Comma separated):
                    </div>
                    <input
                        type="text"
                        value={props.emails}
                        onChange={(event) =>
                            props.setEmails(event.target.value)
                        }
                    />
                </div>
                <div className="divider"></div>
                <div className="field field-half checkbox-field">
                    <label>
                        <input
                            type="checkbox"
                            value={props.sendShortPayEmail}
                            checked={props.sendShortPayEmail}
                            onClick={handleSendShortPayEmailClick}
                        />{" "}
                        Send Short-Pay email notification
                    </label>
                </div>
                <div className="field field-half">
                    <div className="label">
                        Additional Emails (Comma separated):
                    </div>
                    <input
                        type="text"
                        value={props.shortPayEmails}
                        onChange={(event) =>
                            props.setShortPayEmails(event.target.value)
                        }
                    />
                </div>
                <div className="divider"></div>
            </>
        );
    };

    const handlePoMatchChange = (event) => {
        props.setSendEmail(0);
        props.setSendShortPayEmail(0);
        props.setField("po_match", event.target.value);
    };

    const handleCheckPo = (event) => {
        const value = event.target.value;
        if (value === "") {
            return;
        }

        props.checkPo(event.target.value);
    };

    const renderContent = () => {
        if (props.information.fetching) {
            return (
                <div className="ap-invoice-form-container">
                    <div className="loading-container">
                        <i className="fas fa-circle-notch fa-spin" /> Loading
                        data...
                    </div>
                </div>
            );
        }

        let poMessage = null;
        if (props.checkingPoMessage !== "") {
            poMessage = <p className="warning">{props.checkingPoMessage}</p>;
        }

        return (
            <div className="ap-invoice-form-container">
                <h3>Invoice Information</h3>
                {poMessage}
                <div className="field">
                    <div className="label">
                        <strong>PO #s (Comma separated):</strong>
                    </div>
                    <input
                        type="text"
                        value={props.fields.po_number}
                        // disabled={props.poFieldDisabled}
                        onBlur={handleCheckPo}
                        onChange={handleOnChange.bind(this, "po_number")}
                    />
                </div>
                <div className="field">
                    <div className="label">
                        <strong>Vendor:</strong>
                    </div>
                    <select
                        value={props.fields.vendor_id}
                        onChange={handleOnChange.bind(this, "vendor_id")}
                        className="vendor-field"
                    >
                        <option value="">Select Vendor</option>
                        {props.information.vendors.map((vendor) => {
                            return (
                                <option value={vendor.id}>
                                    {vendor.display_name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="field field-half">
                    <div className="label">
                        <strong>
                            Invoice # <span className="required">*</span>:
                        </strong>
                    </div>
                    <input
                        type="text"
                        value={props.fields.invoice_number}
                        onChange={handleOnChange.bind(this, "invoice_number")}
                    />
                </div>
                <div className="field field-half">
                    <div className="label">
                        <strong>
                            Invoice Date <span className="required">*</span>:
                        </strong>
                    </div>
                    <input
                        type="date"
                        value={props.fields.invoice_date}
                        onChange={handleOnChange.bind(this, "invoice_date")}
                    />
                </div>
                <div className="field field-half">
                    <div className="label">
                        <strong>
                            Invoice Amount <span className="required">*</span>:
                        </strong>
                    </div>
                    <input
                        type="number"
                        value={props.fields.amount}
                        onChange={handleOnChange.bind(this, "amount")}
                    />
                </div>
                <div className="field field-half">
                    <div className="label">
                        <strong>
                            Invoice/PO Match?{" "}
                            <span className="required">*</span>:
                        </strong>
                    </div>
                    <select
                        value={props.fields.po_match}
                        onChange={handlePoMatchChange}
                    >
                        <option value="">Select option</option>
                        <option value="1">Invoice and PO match</option>
                        <option value="2">Invoice and PO do not match</option>
                        <option value="3">PO does not exist</option>
                        <option value="4">Vendor does not exist</option>
                    </select>
                </div>
                {renderSendEmailsFields()}
                <div className="field">
                    <div className="label">
                        <strong>
                            PDF File (Max file size: 100 MB)
                            {isUpdate ? null : (
                                <>
                                    {" "}
                                    <span className="required">*</span>
                                </>
                            )}
                            :
                        </strong>
                    </div>
                    <div {...getRootProps({ className: "dropzone-container" })}>
                        <input {...getInputProps()} />
                        Click here to select file
                    </div>
                    {selectedFile}
                </div>
                <div className="textarea-field field">
                    <div className="label">
                        <strong>Notes:</strong>
                    </div>
                    <textarea
                        value={props.fields.notes}
                        onChange={handleOnChange.bind(this, "notes")}
                    />
                </div>
                <div className="textarea-field field">
                    <div className="label">
                        <strong>Memo:</strong>
                    </div>
                    <textarea
                        value={props.fields.memo}
                        onChange={handleOnChange.bind(this, "memo")}
                    />
                </div>
                {renderAuditHistory()}
            </div>
        );
    };

    const handleOnChange = (field, event) => {
        props.setField(field, event.target.value);
    };

    let selectedFile = null;
    if (props.file != null) {
        selectedFile = (
            <div className="shipping-selected-file">
                <b>Selected File:</b> {props.file.name}
            </div>
        );
    }

    let buttonLabel = "Save";
    if (props.saving) {
        buttonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button
            key="cancel-button"
            className="btn btn-light btn-sm action-button-cancel"
            onClick={hideHandler}
        >
            Cancel
        </button>,
        <button
            key="upload-button"
            className="btn btn-light btn-sm action-button"
            onClick={props.saveInvoice}
            disabled={props.saving}
        >
            {buttonLabel}
        </button>,
    ];
    return (
        <Dialog
            name="ap-invoice-form-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 400 }}
            bodyStyle={{ overflow: "initial" }}
            className="ap-invoice-form-dialog"
        >
            {renderContent()}
        </Dialog>
    );
});

export default Form;
