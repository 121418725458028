import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "./Table";
import Form from "./Form";
import Invoices from "./Invoices";
import DeleteModal from "./DeleteModal";
import * as actions from "./ApDashboard.actions";
import * as selectors from "./ApDashboard.selectors";
import "./ApDashboard.scss";
import PqtLayout from "./../PqtLayout";
import { Helmet } from "react-helmet";
import { stringifyEqualityCheck } from "./../../utils";
import * as loginSelectors from "./../Login/Login.selectors";
import * as dialog from "../common/dialog";
import { Redirect } from "react-router-dom";

const ApDashboard = (props) => {
    /* State to props */
    const fetchingPurchaseOrders = useSelector(
        selectors.getFetchingPurchaseOrders
    );
    const purchaseOrdersData = useSelector(
        selectors.getPurchaseOrdersData,
        stringifyEqualityCheck
    );
    const pagination = useSelector(
        selectors.getPagination,
        stringifyEqualityCheck
    );
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const filterFields = useSelector(
        selectors.getFilterFields,
        stringifyEqualityCheck
    );
    const information = useSelector(
        selectors.getInformation,
        stringifyEqualityCheck
    );
    const poNumber = useSelector(selectors.getPoNumber);
    const activeTab = useSelector(selectors.getActiveTab);
    const saving = useSelector(selectors.getSaving);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const fetchingInvoices = useSelector(selectors.getFetchingInvoices);
    const invoices = useSelector(selectors.getInvoices, stringifyEqualityCheck);
    const poFieldDisabled = useSelector(selectors.getPoFieldDisabled);
    const sendEmail = useSelector(selectors.getSendEmail);
    const emails = useSelector(selectors.getEmails, stringifyEqualityCheck);
    const sendShortPayEmail = useSelector(selectors.getSendShortPayEmail);
    const shortPayEmails = useSelector(
        selectors.getShortPayEmails,
        stringifyEqualityCheck
    );
    const idForDelete = useSelector(selectors.getIdForDelete);
    const isDeleting = useSelector(selectors.getIsDeleting);
    const checkingPo = useSelector(selectors.getCheckingPo);
    const checkingPoMessage = useSelector(selectors.getCheckingPoMessage);
    const auditHistory = useSelector(
        selectors.getAuditHistory,
        stringifyEqualityCheck
    );
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setSort = useCallback(
        (column, type) => dispatch(actions.setSort(column, type)),
        [dispatch]
    );
    const setActivePage = useCallback(
        (name, value) => dispatch(actions.setActivePage(name, value)),
        [dispatch]
    );
    const setIdForDelete = useCallback(
        (id) => dispatch(actions.setIdForDelete(id)),
        [dispatch]
    );
    const changeFilter = useCallback(
        (name, value) => dispatch(actions.changeFilter(name, value)),
        [dispatch]
    );
    const fetchPurchaseOrders = useCallback(
        () => dispatch(actions.fetchPurchaseOrders()),
        [dispatch]
    );
    const setPoNumber = useCallback(
        (number) => dispatch(actions.setPoNumber(number)),
        [dispatch]
    );
    const setActiveTab = useCallback(
        (number) => dispatch(actions.setActiveTab(number)),
        [dispatch]
    );
    const setField = useCallback(
        (field, value) => dispatch(actions.setField(field, value)),
        [dispatch]
    );
    const setFile = useCallback(
        (file) => dispatch(actions.setFile(file)),
        [dispatch]
    );
    const saveInvoice = useCallback(
        () => dispatch(actions.saveInvoice()),
        [dispatch]
    );
    const fetchInvoices = useCallback(
        () => dispatch(actions.fetchInvoices()),
        [dispatch]
    );
    const clearForm = useCallback(
        () => dispatch(actions.clearForm()),
        [dispatch]
    );
    const setPoFieldDisabled = useCallback(
        (value) => dispatch(actions.setPoFieldDisabled(value)),
        [dispatch]
    );
    const setSendEmail = useCallback(
        (value) => dispatch(actions.setSendEmail(value)),
        [dispatch]
    );
    const setEmails = useCallback(
        (value) => dispatch(actions.setEmails(value)),
        [dispatch]
    );
    const setSendShortPayEmail = useCallback(
        (value) => dispatch(actions.setSendShortPayEmail(value)),
        [dispatch]
    );
    const setShortPayEmails = useCallback(
        (value) => dispatch(actions.setShortPayEmails(value)),
        [dispatch]
    );
    const deleteInvoice = useCallback(
        (id) => dispatch(actions.deleteInvoice(id)),
        [dispatch]
    );
    const setAuditHistory = useCallback(
        (value) => dispatch(actions.setAuditHistory(value)),
        [dispatch]
    );
    const checkPo = useCallback(
        (value) => dispatch(actions.checkPo(value)),
        [dispatch]
    );
    const showFormModal = useCallback(
        () => dispatch(dialog.actions.showDialog("ap-invoice-form-modal")),
        [dispatch]
    );
    const hideFormModal = useCallback(
        () => dispatch(dialog.actions.hideDialog("ap-invoice-form-modal")),
        [dispatch]
    );
    const showDeleteModal = useCallback(
        () => dispatch(dialog.actions.showDialog("delete-modal")),
        [dispatch]
    );
    const hideDeleteModal = useCallback(
        () => dispatch(dialog.actions.hideDialog("delete-modal")),
        [dispatch]
    );

    /* componentDidMount */
    useEffect(() => {
        dispatch(actions.mountComponent());
    }, [dispatch]);

    // Hugh (61), Kassandra (3566)
    if (user != null && ![61, 3566].includes(parseInt(user.id))) {
        return <Redirect to="/dashboard" />;
    }

    let content = null;
    if (activeTab === 1) {
        content = (
            <Table
                purchaseOrdersData={purchaseOrdersData}
                pagination={pagination}
                fetchingPurchaseOrders={fetchingPurchaseOrders}
                history={props.history}
                sort={sort}
                user={user}
                filterFields={filterFields}
                poNumber={poNumber}
                setSort={setSort}
                setActivePage={setActivePage}
                setIdForDelete={setIdForDelete}
                changeFilter={changeFilter}
                fetchPurchaseOrders={fetchPurchaseOrders}
                setPoNumber={setPoNumber}
                setField={setField}
                showFormModal={showFormModal}
                setPoFieldDisabled={setPoFieldDisabled}
                setSendEmail={setSendEmail}
                setEmails={setEmails}
                setSendShortPayEmail={setSendShortPayEmail}
                setShortPayEmails={setShortPayEmails}
            />
        );
    } else if ([2, 3, 4, 5].includes(activeTab)) {
        content = (
            <Invoices
                purchaseOrdersData={purchaseOrdersData}
                pagination={pagination}
                fetchingPurchaseOrders={fetchingPurchaseOrders}
                history={props.history}
                sort={sort}
                user={user}
                filterFields={filterFields}
                fetchingInvoices={fetchingInvoices}
                invoices={invoices}
                activeTab={activeTab}
                fetchInvoices={fetchInvoices}
                setSort={setSort}
                setActivePage={setActivePage}
                setIdForDelete={setIdForDelete}
                changeFilter={changeFilter}
                setField={setField}
                setPoNumber={setPoNumber}
                showFormModal={showFormModal}
                setPoFieldDisabled={setPoFieldDisabled}
                setSendEmail={setSendEmail}
                setEmails={setEmails}
                setSendShortPayEmail={setSendShortPayEmail}
                setShortPayEmails={setShortPayEmails}
                showDeleteModal={showDeleteModal}
                setAuditHistory={setAuditHistory}
            />
        );
    }

    const handleCreateInvoice = () => {
        setPoFieldDisabled(false);

        if (activeTab === 2) {
            setField("po_match", 1);
        } else if (activeTab === 3) {
            setField("po_match", 2);
        } else if (activeTab === 4) {
            setField("po_match", 3);
        } else if (activeTab === 5) {
            setField("po_match", 4);
        }

        showFormModal();
    };

    return (
        <PqtLayout title="AP Dashboard" history={props.history}>
            <Helmet>
                <title>AP Dashboard - Stagecoach</title>
            </Helmet>
            <div class="ap-dashboard-tabs-container">
                <div
                    class={"tab" + (activeTab === 1 ? " active" : "")}
                    onClick={setActiveTab.bind(this, 1)}
                >
                    PO Search
                </div>
                <div
                    class={"tab" + (activeTab === 2 ? " active" : "")}
                    onClick={setActiveTab.bind(this, 2)}
                >
                    Invoice/PO match
                </div>
                <div
                    class={"tab" + (activeTab === 3 ? " active" : "")}
                    onClick={setActiveTab.bind(this, 3)}
                >
                    Invoice/PO do not match
                </div>
                <div
                    class={"tab" + (activeTab === 4 ? " active" : "")}
                    onClick={setActiveTab.bind(this, 4)}
                >
                    PO does not exist
                </div>
                <div
                    class={"tab" + (activeTab === 5 ? " active" : "")}
                    onClick={setActiveTab.bind(this, 5)}
                >
                    Vendor does not exist
                </div>
            </div>
            <div className="create-button-container">
                <button onClick={handleCreateInvoice}>
                    <i className="fas fa-plus"></i> Create Invoice
                </button>
            </div>
            <div className="ap-dashboard-container">
                {content}
                <Form
                    information={information}
                    fields={fields}
                    file={file}
                    saving={saving}
                    poFieldDisabled={poFieldDisabled}
                    sendEmail={sendEmail}
                    emails={emails}
                    sendShortPayEmail={sendShortPayEmail}
                    shortPayEmails={shortPayEmails}
                    auditHistory={auditHistory}
                    checkingPo={checkingPo}
                    checkingPoMessage={checkingPoMessage}
                    setField={setField}
                    setFile={setFile}
                    saveInvoice={saveInvoice}
                    clearForm={clearForm}
                    hideFormModal={hideFormModal}
                    setSendEmail={setSendEmail}
                    setEmails={setEmails}
                    setSendShortPayEmail={setSendShortPayEmail}
                    setShortPayEmails={setShortPayEmails}
                    setAuditHistory={setAuditHistory}
                    checkPo={checkPo}
                />
            </div>
            <DeleteModal
                idForDelete={idForDelete}
                isDeleting={isDeleting}
                deleteInvoice={deleteInvoice}
                hideDeleteModal={hideDeleteModal}
                setIdForDelete={setIdForDelete}
            />
        </PqtLayout>
    );
};

export default ApDashboard;
