import React from 'react';
import { store } from 'react-notifications-component';
import * as loginActions from './components/Login/Login.actions';
import * as pqtLayoutSelectors from './components/PqtLayout/PqtLayout.selectors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment-timezone';

export const showNotification = (title, message, type, duration = 4000, container = 'top-right') => {
    store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: container,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: true,
            showIcon: false,
        },
    });
}

export const showDataSavedNotification = () => {
    showNotification(
        'Data Saved!',
        'Your data has been saved successfully!',
        'success'
    );
}

export const showDataDeletedNotification = () => {
    showNotification(
        'Element deleted!',
        'The element has been deleted successfully!',
        'success'
    );
}

export const showErrorNotification = () => {
    showNotification(
        'Error',
        'An error has occurred',
        'danger'
    );
}

export const showCompleteDataNotification = () => {
    showNotification(
        'Complete the information',
        'Complete all the required fields',
        'info'
    );
}

export function findPermission(permssions, name) {
    let permission = [];
    for (let i = 0; i < permssions.length; i++) {
        if (permssions[i]['name'] === name) {
            for (let j = 0; j < permssions[i]['subnames'].length; j++) {
                permission[permssions[i]['subnames'][j]['name']] = permssions[i]['subnames'][j]['selected'];
            }
        }
    }
    return permission;
}

export const validateAuthInResponse = (error, type) => dispatch => {
    if (error.response && error.response.status === 403) {
        dispatch(loginActions.logout());
    } else if (error.response && error.response.status === 401) {
        dispatch({ type, payload: null });
        showNotification('Unauthorized', 'You are not authorized to see this page.', 'warning');
    } else if (error.response && error.response.status === 404) {
        dispatch({ type, payload: null });
        showNotification('Not Found', 'This page does not exist.', 'warning');
    } else {
        dispatch({ type, payload: null });
        showNotification('Error', 'An error has occurred!', 'danger');
    }
}

export const validateMultiAuthInResponse = (error, types) => dispatch => {
    if (error.response && error.response.status === 403) {
        dispatch(loginActions.logout());
    } else if (error.response && error.response.status === 401) {
        for (let i = 0; i < types.length; i++) {
            dispatch({ type: types[i], payload: null });
        }
        showNotification('Unauthorized', 'You are not authorized to see this page.', 'warning');
    } else {
        for (let i = 0; i < types.length; i++) {
            dispatch({ type: types[i], payload: null });
        }
        showNotification('Error', 'An error has occurred!', 'danger');
    }
}

export const confirmModal = (deleteCallback, title = null, description = null) => {
    if (title === null) {
        title = 'Do you wan to remove this item?';
    }

    if (description === null) {
        description = 'This action cannot be undone.';
    }

    confirmAlert({
        customUI: ({ onClose }) => {
            const acceptHandler = () => {
                deleteCallback();
                onClose();
            }
            return (
                <div className="confirm-alert-container">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <div className="buttons">
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={acceptHandler}>Accept</button>
                    </div>
                </div>
            );
        }
    });
}

export const findPqtUserType = user => {
    if (user === null) {
        return 9;
    }

    for (let i = 0; i < user.permissions.length; i++) {
        const element = user.permissions[i];
        if (element.name === 'PQT' && element.subnames[0].selected) {    // Super Dashboard
            return 1;
        } else if (element.name === 'PQT' && element.subnames[1].selected) {     // Super Manager
            return 2;
        } else if (element.name === 'PQT' && element.subnames[2].selected) {     // Department Manager
            return 3;
        } else if (element.name === 'PQT' && element.subnames[3].selected) {     // CSR
            return 4;
        } else if (element.name === 'PQT' && element.subnames[4].selected) {     // Sales
            return 5;
        } else if (element.name === 'PQT' && element.subnames[5].selected) {     // Worker
            return 6;
        }
    }
}

export const dateToTimezone = (date, format) => {
    if (date == null) {
        return moment().tz('America/Chicago').format(format);
    } else {
        return moment.tz(date, process.env.REACT_APP_TIMEZONE).tz('America/Chicago').format(format);
    }
}

export const sendWsMessage = message => (dispatch, getState) => {
    const ws = pqtLayoutSelectors.getWs(getState());
    message.uuid = ws.uuid;
    if (ws.connection !== null) {
        ws.connection.send(JSON.stringify({ message: JSON.stringify(message) }));
    }
}

export const scrollElementToTheBottom = (ref, callback = null) => {
    if (ref != null && ref.current != null) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (callback != null) {
            callback();
        }
    }
}

export const calculateTimeFromNow = date => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let difference = moment().tz('America/Chicago').unix() - moment.tz(date, process.env.REACT_APP_TIMEZONE).unix();
    if (difference > 0) {
        days = Math.floor(difference / 86400);
    }

    difference -= days * 86400;
    if (difference > 0) {
        hours = Math.floor(difference / 3600);
    }

    difference -= hours * 3600;
    if (difference > 0) {
        minutes = Math.floor(difference / 60);
    }

    return { days, hours, minutes };
}

export const calculateTimeFromDates = (startDate, endDate) => {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let difference = moment.tz(endDate, process.env.REACT_APP_TIMEZONE).unix() - moment.tz(startDate, process.env.REACT_APP_TIMEZONE).unix();
    if (difference > 0) {
        days = Math.floor(difference / 86400);
    }

    difference -= days * 86400;
    if (difference > 0) {
        hours = Math.floor(difference / 3600);
    }

    difference -= hours * 3600;
    if (difference > 0) {
        minutes = Math.floor(difference / 60);
    }

    return { days, hours, minutes };
}

export const stringifyEqualityCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const calculateWorkingDays = (dueDate) => {
    if (!dueDate) {
        return 0;
    }

    let dueDateObj = moment.tz(dueDate, process.env.REACT_APP_TIMEZONE);
    let now = moment.tz();

    if (now >= dueDateObj) {
        return 0;
    }

    let count = 0;
    while (now < dueDateObj) {
        now.add(1, 'days');
        if (now.day() !== 0 && now.day() !== 6) {
            count++;
        }
    }

    return count;
}

export const empty = (field) => {
    return field === undefined || field == null || field === ''; 
}

export const isEstimator = (user) => {
    if (user != null && [36, 52, 58, 59, 61, 63, 1180].includes(parseInt(user.id))) {   // Mike, John, Gary, Robert, Hugh, Norm, Edward
        return true;
    }
    return false;
}