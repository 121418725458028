import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { dateToTimezone, calculateWorkingDays } from './../../../utils';
import numeral from 'numeral';
import { showNotification } from './../../../utils';

const Form = React.memo((props) => {
    if (!props.isEditing) {
        return null;
    }

    let disableFields = false;
    const pqtType = props.user !== null && props.user.pqt_type;
    if (parseInt(pqtType) === 5) {
        disableFields = true;
    }

    let customer = null;
    for (let i = 0; i < props.informationCustomers.length; i++) {
        if (parseInt(props.informationCustomers[i].id) === parseInt(props.trackingFields.customer_id)) {
            customer = props.informationCustomers[i];
            break;
        }
    }

    let holdMessageContainer = null;
    let disableByHold = false;
    if (customer != null) {
        if (parseInt(customer.on_hold_status) === 2 || parseInt(customer.on_hold_status) === 3) {
            disableByHold = true;
        }

        if (parseInt(customer.on_hold_status) === 2 && parseInt(customer.check_bounced) === 0) {
            holdMessageContainer = (
                <Fragment>
                    <div className="hold-message-overlay1"></div>
                    <div className="hold-message-overlay"></div>
                    <div className="hold-message-container">
                        <div className="hold-message">
                            <div>Account is on hold.</div>
                            <div>See accounting.</div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (parseInt(customer.on_hold_status) === 2 && parseInt(customer.check_bounced) === 1) {
            holdMessageContainer = (
                <Fragment>
                    <div className="hold-message-overlay1"></div>
                    <div className="hold-message-overlay"></div>
                    <div className="hold-message-container">
                        <div className="hold-message">
                            <div>Account is on hold.</div>
                            <div>Bounced Check.</div>
                            <div>See accounting.</div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (parseInt(customer.on_hold_status) === 3) {
            holdMessageContainer = (
                <Fragment>
                    <div className="hold-message-overlay1"></div>
                    <div className="hold-message-overlay"></div>
                    <div className="hold-message-container">
                        <div className="hold-message">
                            Business Closed.
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    if (props.isFetchingTracking || props.isFetchingInformation) {
        return (
            <div className="form-container">
                <form className="text-center">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </form>
            </div>
        );
    }

    const setFieldHandler = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(name, value);

        if (name === 'customer_id') {
            props.getPostageEscrow(value, true);

            for (let i = 0; i < props.informationCustomers.length; i++) {
                if (parseInt(props.informationCustomers[i].id) === parseInt(value)) {
                    let value = props.informationCustomers[i].sales_rep_id;
                    if (props.informationCustomers[i].sales_rep_id == null) {
                        value = '';
                    }

                    props.setField('sales_rep_id', value);
                    break;
                }
            }
        }
    }

    const renderTextInput = (name, disabled) => {
        let disableField = false;
        if (name === 'job_number') {
            disableField = disabled || disableByHold;
        } else {
            disableField = disableFields || disabled || disableByHold;
        }

        return (
            <div className="input-container">
                <input
                    type="text"
                    name={name}
                    value={props.trackingFields[name] === undefined ? '' : props.trackingFields[name]}
                    onChange={setFieldHandler.bind(this, name)}
                    disabled={disableField}
                />
            </div>
        );
    };

    const renderNumberInput = (name, disabled) => (
        <div className="input-container">
            <input
                type="number"
                name={name}
                value={props.trackingFields[name] === undefined ? '' : props.trackingFields[name]}
                onChange={setFieldHandler.bind(this, name)}
                disabled={disableFields || disabled || disableByHold}
            />
        </div>
    );

    const renderSelect = (name, options, disabled) => {
        let disabledByHoldS = disableByHold;
        if (name === 'customer_id') {
            disabledByHoldS = false;
        }

        return (
            <div className="input-container">
                <select
                    name={name}
                    value={props.trackingFields[name] === undefined ? '' : props.trackingFields[name]}
                    onChange={setFieldHandler.bind(this, name)}
                    disabled={disableFields || disabled || disabledByHoldS}
                >
                    {options}
                </select>
            </div>
        );
    };

    const setDateFieldHandler = (name, oldValue, event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');

        if (props.user &&
            !['hugh@absolutecolor.net', 'robert@acmailplex.com', 'gary@acmailplex.com'].includes(props.user.email) &&
            value === moment().tz('America/Chicago').format('YYYY-MM-DD')
        ) {
            const oldValueParsed = oldValue == null || oldValue === '' || oldValue === '0000-00-00' ? '' : moment(oldValue).format('YYYY-MM-DD');

            showNotification(
                'Incorrect Due Date',
                'You cannot select today\'s date for the Due Date. If you want to move your job to today please contact Robert, Gary or Hugh.',
                'warning',
                8000,
                'top-center'
            );

            props.setField(name, '');
            setTimeout(() => {
                props.setField(name, oldValueParsed);
            }, 100);
        } else {
            props.setField(name, value);
        }
    };

    const renderDateInput = (name) => {
        let dayPickerProps = {
            disabledDays: {
                before: new Date(),
            }
        };
        if (parseInt(pqtType) === 5) {
            dayPickerProps = {
                disabledDays: {
                    before: new Date(props.trackingFields['due_date']),
                }
            };
        }
        if (props.trackingFields.last_paused_due_date && props.user && !['hugh@absolutecolor.net', 'robert@acmailplex.com', 'gary@acmailplex.com'].includes(props.user.email)) {
            dayPickerProps = {
                disabledDays: {
                    before: new Date(props.trackingFields.last_paused_due_date),
                }
            };
        }

        const field = props.trackingFields[name];
        const fieldValue = field == null || field === '' || field === '0000-00-00' ? '' : moment(field).format('MM/DD/YYYY');
        return (
            <div className="input-container">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name, fieldValue)}
                    value={fieldValue}
                    dayPickerProps={dayPickerProps}
                    component={(props) => {
                        return <input {...props} value={props.value} readOnly />;
                    }}
                />
            </div>
        );
    }

    let createdDate = dateToTimezone(null, 'MMMM Do YYYY, h:mm a');
    if (props.trackingFields.created_at) {
        createdDate = dateToTimezone(props.trackingFields.created_at, 'MMMM Do YYYY, h:mm a');
    }

    const saveHandler = (event) => {
        event.preventDefault();
        props.saveTracking();

        if (props.id) {
            props.getPostageEscrow(props.id, false);
        }
    };

    const cancelHandler = (event) => {
        event.preventDefault();
        props.setIsEditing(false);

        if (props.id) {
            props.getPostageEscrow(props.id, false);
        }
    };

    let customerOptions = [<option value="" key="customer-option-empty">Select an option</option>];
    for (let i = 0; i < props.informationCustomers.length; i++) {
        customerOptions.push(
            <option value={props.informationCustomers[i].id} key={'customer-option-empty' + i}>
                {props.informationCustomers[i].display_name}
            </option>
        );
    }

    let salesRep = 'N/A';
    let salesRepOptions = [<option value="" key="sales-option-empty">Select an option</option>];
    for (let i = 0; i < props.informationSalesReps.length; i++) {
        if (parseInt(props.informationSalesReps[i].id) === parseInt(props.trackingFields['sales_rep_id'])) {
            salesRep = props.informationSalesReps[i].name;
            break;
        }

        salesRepOptions.push(
            <option value={props.informationSalesReps[i].id} key={'sales-option-' + i}>
                {props.informationSalesReps[i].name}
            </option>
        );
    }

    let csrOptions = [<option value="" key="csrs-option-empty">Select an option</option>];
    for (let i = 0; i < props.informationCsrs.length; i++) {
        csrOptions.push(
            <option value={props.informationCsrs[i].id} key={'csrs-option-' + i}>
                {props.informationCsrs[i].name}
            </option>
        );
    }

    let saveButton = <button onClick={saveHandler}><i className="fas fa-save"></i> Save</button>;
    if (props.savingTracking) {
        saveButton = <button disabled><i className="fas fa-circle-notch fa-spin" /></button>;
    }
    if (disableByHold) {
        saveButton = null;
    }

    let cancelButton = <button onClick={cancelHandler}><i className="fas fa-ban"></i> Cancel</button>;
    if (props.id === null) {
        cancelButton = null;
    }

    let postageEscrow = numeral(props.postageEscrow).format('$0,0.00');
    if (props.isLoadingPostageEscrow) {
        postageEscrow = <i className="fas fa-circle-notch fa-spin" />;
    }

    let postageEscrowRow = null;
    const userId = props.user !== null ? parseInt(props.user.id) : null;
    if (userId === 61) {
        let postageEscrowClassName = '';
        if (props.postageEscrow < 0) {
            postageEscrowClassName = ' postage-red';
        }
        postageEscrowRow = (
            <Fragment>
                <div className={'input-container postage-escrow-input' + postageEscrowClassName}><b>Postage Escrow available:</b> {postageEscrow}</div>
            </Fragment>
        );
    }

    let workingDays = calculateWorkingDays(props.trackingFields.due_date);

    let salesRepRow = <div className="input-container">{salesRep}</div>;
    if (salesRep === 'N/A') {
        salesRepRow = renderSelect('sales_rep_id', salesRepOptions);
    }

    return (
        <div className="form-container">
            <form>
                <div className="title">{props.id === null ? 'New Tracker Information' : 'Edit Tracker Information'}</div>
                {holdMessageContainer}
                <div className="information">
                    <div className="column1">
                        <div className="label">Customer <span className="required">*</span>:</div>
                        {renderSelect('customer_id', customerOptions)}
                        <div className="label">PO Number:</div>
                        {renderTextInput('job_number')}
                        <div className="label">Due Date <span className="required">*</span>:</div>
                        {renderDateInput('due_date')}
                        <div className="label">Sales Rep <span className="required">*</span>:</div>
                        {salesRepRow}
                        <div className="label">CSR <span className="required">*</span>:</div>
                        {renderSelect('csr_id', csrOptions)}
                        <div className="label">Project Type <span className="required">*</span>:</div>
                        {renderSelect('project_type', [
                            <option value="">Select an option</option>,
                            <option value="1">Regular</option>,
                            <option value="2">Daily</option>,
                            <option value="3">Weekly</option>,
                            <option value="4">Monthly</option>,
                            <option value="5">Partial</option>,
                        ])}
                        <div className="label">Product Type <span className="required">*</span>:</div>
                        {renderSelect('product_type', [
                            <option value="">Select an option</option>,
                            <option value="1">Direct Mail</option>,
                            <option value="2">Offset Print</option>,
                            <option value="3">Digital Print</option>,
                            <option value="4">Wide Format</option>,
                            <option value="5">Promotional Items</option>,
                            <option value="6">Apparel & Customization</option>,
                            <option value="7">Vended Work</option>,
                            <option value="8">Warehouse/Inventory</option>,
                            <option value="9">Software/Solution</option>,
                            <option value="10">Large Format</option>,
                        ])}
                        <div className="label">Quote #:</div>
                        {renderNumberInput('quote_number')}
                        <div className="label">Quantity <span className="required">*</span>:</div>
                        {renderNumberInput('quantity')}
                        {postageEscrowRow}
                    </div>
                    <div className="column2">
                        <div className="label project-mail-label">
                            <input
                                type="checkbox"
                                value={props.trackingFields.is_mail_project === undefined ? '' : props.trackingFields.is_mail_project}
                                checked={props.trackingFields.is_mail_project === undefined ? '' : props.trackingFields.is_mail_project}
                                onChange={setFieldHandler.bind(this, 'is_mail_project')}
                            />
                            Project Mail 1:
                        </div>
                        {renderSelect('project_mail', [
                            <option value="">Select an option</option>,
                            <option value="1">Customer Permit</option>,
                            <option value="2">ACM permit #1</option>,
                            <option value="3">ACM Meter</option>,
                            <option value="4">ACM Stamp</option>,
                        ], !props.trackingFields.is_mail_project)}
                        <div className="label">Permit # 1:</div>
                        {renderTextInput('permit_number', !props.trackingFields.is_mail_project)}
                        <div className="label">Mail Type 1:</div>
                        {renderSelect('mail_type', [
                            <option value="">Select an option</option>,
                            <option value="1">FCP: FIRST CLASS PRESORT</option>,
                            <option value="2">FC: FIRST CLASS</option>,
                            <option value="3">STD: STANDARD</option>,
                            <option value="4">NP: NON-PROFIT</option>,
                            <option value="5">PER: PERIODICAL</option>,
                            <option value="6">BPM: BOUND PRINTED MAT</option>,
                            <option value="7">PIR: PRIORITY MAIL</option>,
                            <option value="8">PS: PARCEL SELECT</option>,
                            <option value="9">INT: INTERNATIONAL MAIL</option>,
                        ], !props.trackingFields.is_mail_project)}
                        <div className="label">Post Office 1:</div>
                        {renderTextInput('post_office', !props.trackingFields.is_mail_project)}
                        <hr />
                        <div className="label project-mail-label">
                            <input
                                type="checkbox"
                                value={props.trackingFields.is_mail_project_2 === undefined ? '' : props.trackingFields.is_mail_project_2}
                                checked={props.trackingFields.is_mail_project_2 === undefined ? '' : props.trackingFields.is_mail_project_2}
                                onChange={setFieldHandler.bind(this, 'is_mail_project_2')}
                            />
                            Project Mail 2:
                        </div>
                        {renderSelect('project_mail_2', [
                            <option value="">Select an option</option>,
                            <option value="1">Customer Permit</option>,
                            <option value="2">ACM permit #1</option>,
                            <option value="3">ACM Meter</option>,
                            <option value="4">ACM Stamp</option>,
                        ], !props.trackingFields.is_mail_project_2)}
                        <div className="label">Permit # 2:</div>
                        {renderTextInput('permit_number_2', !props.trackingFields.is_mail_project_2)}
                        <div className="label">Mail Type 2:</div>
                        {renderSelect('mail_type_2', [
                            <option value="">Select an option</option>,
                            <option value="1">FCP: FIRST CLASS PRESORT</option>,
                            <option value="2">FC: FIRST CLASS</option>,
                            <option value="3">STD: STANDARD</option>,
                            <option value="4">NP: NON-PROFIT</option>,
                            <option value="5">PER: PERIODICAL</option>,
                            <option value="6">BPM: BOUND PRINTED MAT</option>,
                            <option value="7">PIR: PRIORITY MAIL</option>,
                            <option value="8">PS: PARCEL SELECT</option>,
                            <option value="9">INT: INTERNATIONAL MAIL</option>,
                        ], !props.trackingFields.is_mail_project_2)}
                        <div className="label">Post Office 2:</div>
                        {renderTextInput('post_office_2', !props.trackingFields.is_mail_project_2)}
                    </div>
                    <div className="bottom">
                        <div className="label description-container">Description <span className="required">*</span>: {renderTextInput('job_description')}</div>
                        <div className="working-days">Based on your due date. This job has {workingDays} working days.</div>
                        <div className="label">Date Created: {createdDate}</div>
                    </div>
                </div>
                <div className="buttons-container">
                    <div className="cancel-button">
                        {cancelButton}
                    </div>
                    <div className="save-button">
                        {saveButton}
                    </div>
                </div>
            </form>
        </div>
    );
});

Form.propTypes = {

};

export default Form;
