import React, { Fragment } from 'react';
//import PropTypes from 'prop-types';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { dateToTimezone } from './../../../utils';
import PqtPagination from './../../PqtPagination';
import { Link } from 'react-router-dom';

const MyJobsBoard = React.memo(props => {
    const confirmHandler = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    }

    // const openChatHandler = (id, title) => {
    //     props.loadChats();
    //     props.loadChat(1, { element_id: id });
    //     props.setChatShowField('room', true);
    //     props.setChatShowField('members', false);
    //     props.setChatType(1);
    //     props.seChattElementId(id);
    //     props.setChatTitle(title);
    //     props.fetchData({
    //         fetchLateJobs: 1,
    //         fetchMyJobs: 1,
    //     }, true);
    // };

    const renderJobs = () => {
        if (props.fetchingMyJobs) {
            return (
                <tr>
                    <td colSpan="14"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.myJobsData.length === 0) {
            return (
                <tr>
                    <td colSpan="14">No Results</td>
                </tr>
            );
        }

        return props.myJobsData.map((job, index) => {
            let box = '';
            if (job.status === 4) {
                box = 'purple-box';
            } else if (job.days_left <= 0) {
                box = 'red-box';
            } else if (job.days_left < 7) {
                box = 'yellow-box';
            } else if (job.days_left < 14) {
                box = 'blue-box';
            } else {
                box = 'green-box';
            }

            let statusBox = 'status-box';
            if (job.status === 1) {
                statusBox = 'status-box blue-box';
            } else if (job.status === 2) {
                statusBox = 'status-box green-box';
            } else if (job.status === 3) {
                statusBox = 'status-box red-box';
            } else if (job.status === 4) {
                statusBox = 'status-box purple-box';
            } else if (job.status === 5) {
                statusBox = 'status-box yellow-box';
            }

            let departments = [];
            for (let i = 0; i < job.departments.length; i++) {
                let separator = ' | ';
                if (i === job.departments.length - 1) {
                    separator = null;
                }

                let spanClass = box;
                if (parseInt(job.departments[i].status) === 4) {
                    spanClass = 'finished';
                }

                let departmentTime = null;
                if (job.departments[i].lifecycle != null) {
                    departmentTime = ' (' + job.departments[i].lifecycle + ')';
                }

                departments.push(<Fragment key={'job-department-' + i}><span className={spanClass}>{job.departments[i].name}{departmentTime}</span>{separator}</Fragment>);
            }

            // let deleteButton = null;
            // const pqtType = props.user !== null && props.user.pqt_type;
            // if (![6, 7].includes(pqtType)) {
            //     deleteButton = (
            //         <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
            //             <i className="fas fa-trash" onClick={confirmHandler.bind(this, job.id)} />
            //         </Tooltip>
            //     );
            // }

            // let chatTitle = 'SC-' + job.id + ' (' + job.job_description + ')';
            // let chatClassName = 'chat';
            // if (job.has_unread_messages) {
            //     chatClassName = 'chat unread';
            // }

            let csrName = job.csr_name;
            const csrNameParts = csrName.split(' ');
            if (csrNameParts.length > 0) {
                csrName = csrNameParts[0];
            }

            let salesName = job.sales_rep_name;
            const salesNameParts = salesName.split(' ');
            if (salesNameParts.length > 0) {
                salesName = salesNameParts[0];
            }

            let outOnProofContainer = null;
            if (job.out_on_proof) {
                outOnProofContainer = (<span className="out-on-proof">(Proof out - {dateToTimezone(job.out_on_proof_datetime, 'MM/DD/YYYY hh:mm a')})</span>);
            }

            let onPauseText = null;
            if (parseInt(job.status) === 5) {
                let dueDateRow = null;
                if (job.paused_due_date) {
                    dueDateRow = <div className="paused-text">Due: {dateToTimezone(job.paused_due_date, 'MM/DD/YYYY')}</div>;
                }

                onPauseText = (
                    <Fragment>
                        <div className="paused-text">Paused</div>
                        {dueDateRow}
                    </Fragment>
                );
            }

            let deleteButton = null;
            if (props.user && [61, 64, 73, 2419].includes(parseInt(props.user.id))) {     // Hugh (61), Christy.acm (64), Christy.net (73), Stephanie (2419)
                deleteButton = (
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={confirmHandler.bind(this, job.id)} />
                    </Tooltip>
                );
            }

            let postageStatusBox = '';
            let postagePayment = <td className="text-center">-</td>;
            if (job.postage_invoice_id != null) {
                postagePayment = job.is_postage_paid;

                postageStatusBox = 'status-box red-box';
                if (parseInt(job.postage_invoice_method) === 1) {
                    postageStatusBox = 'status-box yellow-box';
                }
                if (parseInt(job.is_postage_paid) === 1) {
                    postageStatusBox = 'status-box green-box';
                }

                postagePayment = <td className={postageStatusBox}><i className="fas fa-circle" /></td>;
            }

            return (
                <tr key={'my-jobs-job-' + index}>
                    <td className="large-desktop-only">{dateToTimezone(job.created_at, 'MM/DD/YY')}</td>
                    <td className={box}>{job.id}</td>
                    <td className={box}>{job.job_number}</td>
                    <td>{csrName}</td>
                    <td>{salesName}</td>
                    {/* <td className={chatClassName}><i class="fas fa-comment-dots" onClick={openChatHandler.bind(this, job.id, chatTitle)} /></td> */}
                    <td className={box + ' text-center'}>
                        {dateToTimezone(job.due_date, 'MM/DD/YY')}
                        {onPauseText}
                    </td>
                    <td className={box + ' text-center'}>{job.days_left}</td>
                    {postagePayment}
                    <td>
                        <div>{job.job_description}</div>
                        <div><b>Customer:</b> {job.customer_name} {outOnProofContainer}</div>
                        <div><b>Product Type:</b> {job.product_type_name}</div>
                    </td>
                    <td className="text-right">{numeral(job.quantity).format('0,0')}</td>
                    <td className={statusBox}><i className="fas fa-dot-circle" /></td>
                    <td className="text-cent1er">{job.lifecycle}</td>
                    <td>{departments}</td>
                    <td>
                        <div className="actions">
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <Link to={'/tracking/' + job.id}><i className="fas fa-edit" /></Link>
                            </Tooltip>
                            {deleteButton}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setShowFinishedHandler = (event) => {
        props.setShowFinished(event.target.checked);
    }

    const setSortHandler = (field, value) => {
        props.setSort('myJobs', field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, 'asc');
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.myJobs.column === column) {
            if (props.sort.myJobs.type === 'asc') {
                sortFunc = setSortHandler.bind(this, column, 'desc');
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, 'asc');
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return <th className={className} onClick={sortFunc}>{name} {sortIcon}</th>;
    };

    return (
        <div className="my-jobs-board-container">
            <div className="title">
                <div><b>My Jobs Board</b></div>
                <div className="show-finished-jobs">
                    <input type="checkbox" checked={props.showFinished} onClick={setShowFinishedHandler} /> Show Finished Jobs
                </div>
            </div>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader('Enter', 'created_at', 'large-desktop-only')}
                            {renderHeader('SC #', 'id')}
                            {renderHeader('PO #', 'job_number')}
                            {renderHeader('CSR', 'csr_name')}
                            {renderHeader('Sales', 'sales_rep_name')}
                            {/* <th className="text-center">Chat</th> */}
                            {renderHeader('Due', 'due_date', 'text-center')}
                            {renderHeader('Days Left', 'days_left', 'text-center')}
                            <th className="text-center">Postage Payment</th>
                            {renderHeader('Description | Customer', 'job_description')}
                            {renderHeader('Qty', 'quantity', 'text-right')}
                            {renderHeader('Status', 'status', 'text-center')}
                            {renderHeader('Lifecycle', 'lifecycle', 'text-center')}
                            <th className="text-center">Required Departments</th>
                            <th style={{ width: 55 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderJobs()}
                    </tbody>
                </table>
                <PqtPagination
                    pagination={props.myJobsPagination}
                    setActivePage={props.setActivePage.bind(this, 'myJobs')}
                />
            </div>
        </div>
    );
});

export default MyJobsBoard;
