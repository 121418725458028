export const FETCH_PURCHASE_ORDERS = 'AP_DASHBOARD/FETCH_PURCHASE_ORDERS';
export const FETCH_PURCHASE_ORDERS_SUCCESS = 'AP_DASHBOARD/FETCH_PURCHASE_ORDERS_SUCCESS';
export const FETCH_PURCHASE_ORDERS_ERROR = 'AP_DASHBOARD/FETCH_PURCHASE_ORDERS_ERROR';
export const FETCH_INVOICES = 'AP_DASHBOARD/FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'AP_DASHBOARD/FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'AP_DASHBOARD/FETCH_INVOICES_ERROR';
export const DELETE_INVOICE = 'AP_DASHBOARD/DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'AP_DASHBOARD/DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'AP_DASHBOARD/DELETE_INVOICE_ERROR';
export const FETCH_INFORMATION = 'AP_DASHBOARD/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'AP_DASHBOARD/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'AP_DASHBOARD/FETCH_INFORMATION_ERROR';
export const SAVE_INVOICE = 'AP_DASHBOARD/SAVE_INVOICE';
export const SAVE_INVOICE_SUCCESS = 'AP_DASHBOARD/SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_ERROR = 'AP_DASHBOARD/SAVE_INVOICE_ERROR';
export const CHECK_PO = 'AP_DASHBOARD/CHECK_PO';
export const CHECK_PO_SUCCESS = 'AP_DASHBOARD/CHECK_PO_SUCCESS';
export const CHECK_PO_ERROR = 'AP_DASHBOARD/CHECK_PO_ERROR';
export const RESET_STATE = 'AP_DASHBOARD/RESET_STATE';
export const SET_ID = 'AP_DASHBOARD/SET_ID';
export const SET_PO_NUMBER = 'AP_DASHBOARD/SET_PO_NUMBER';
export const SET_SORT = 'AP_DASHBOARD/SET_SORT';
export const SET_ACTIVE_PAGE = 'AP_DASHBOARD/SET_ACTIVE_PAGE';
export const SET_ID_FOR_DELETE = 'AP_DASHBOARD/SET_ID_FOR_DELETE';
export const SET_ACTIVE_TAB = 'AP_DASHBOARD/SET_ACTIVE_TAB';
export const CHANGE_FILTER = 'AP_DASHBOARD/CHANGE_FILTER';
export const SET_FILE = 'AP_DASHBOARD/SET_FILE';
export const SET_UPLOAD_INVOICE = 'AP_DASHBOARD/SET_UPLOAD_INVOICE';
export const SET_FIELD = 'AP_DASHBOARD/SET_FIELD';
export const CLEAR_FORM = 'AP_DASHBOARD/CLEAR_FORM';
export const SET_PO_FIELD_DISABLED = 'AP_DASHBOARD/SET_PO_FIELD_DISABLED';
export const SET_SEND_EMAIL = 'AP_DASHBOARD/SET_SEND_EMAIL';
export const SET_EMAILS = 'AP_DASHBOARD/SET_EMAILS';
export const SET_SEND_SHORT_PAY_EMAIL = 'AP_DASHBOARD/SET_SEND_SHORT_PAY_EMAIL';
export const SET_SHORT_PAY_EMAILS = 'AP_DASHBOARD/SET_SHORT_PAY_EMAILS';
export const SET_AUDIT_HISTORY = 'AP_DASHBOARD/SET_AUDIT_HISTORY';