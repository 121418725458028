import React, { useState } from "react";
//import PropTypes from 'prop-types';
import { Dialog } from "./../../common/dialog";

const DeleteModal = React.memo((props) => {
    const [confirmValue, setConfirmValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const hideHandler = () => {
        setConfirmValue("");
        setErrorMessage("");
        props.setIdForDelete(null);
        props.hideDeleteModal();
    };

    const deleteHandler = () => {
        if (confirmValue === "") {
            setErrorMessage("The Confirm is empty");
            return;
        }

        if (confirmValue.toLowerCase() !== "delete") {
            setErrorMessage('Write "delete" to confirm');
            return;
        }

        props.deleteInvoice(props.idForDelete);
        setConfirmValue("");
    };

    const changeHandler = (event) => {
        setErrorMessage("");
        setConfirmValue(event.target.value);
    };

    let label = "Delete";
    if (props.isDeleting) {
        label = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button
            key="cancel-button"
            className="btn btn-light btn-sm action-button-cancel"
            onClick={hideHandler}
        >
            Cancel
        </button>,
        <button
            key="delete-button"
            className="btn btn-light btn-sm action-button"
            onClick={deleteHandler}
            disabled={props.isDeleting}
        >
            {label}
        </button>,
    ];
    return (
        <Dialog
            name="delete-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: "initial" }}
            className="delete-dialog"
        >
            <div className="delete-container">
                <h1>Delete Invoice?</h1>
                <p className="warning">
                    Warning: This action cannot be undone.
                </p>
                <p>
                    Confirm that you want to delete this item by writing{" "}
                    <b>
                        <i>delete</i>
                    </b>{" "}
                    below:
                </p>
                <h2>Confirm:</h2>
                <div>
                    <input
                        type="text"
                        value={confirmValue}
                        onChange={changeHandler}
                        placeholder={'Type "delete" to confirm'}
                    />
                </div>
                <div className="error-message">
                    {errorMessage !== "" && errorMessage}
                </div>
            </div>
        </Dialog>
    );
});

export default DeleteModal;
